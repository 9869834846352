// eslint-disable-next-line no-unused-vars
const prod = {
  url: {
    KEYCLOAK_BASE_URL: 'https://cloak.sitecenter.org',
    API_BASE_URL: 'https://api.sitecenter.org',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x',
  },
  keycloakInit: {
    url: "https://cloak.sitecenter.org/auth",
    realm: "sitecenter",
    clientId: "sitecenter-web",
  }
}
export const config = prod
